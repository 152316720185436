<template>
  <v-data-table
    :page="page"
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :items-per-page="itemsPerPage"
    :server-items-length="totalCount"
    :options.sync="options"
    :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isFetchingItems: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Adı", value: "name", sortable: true },
        { text: "Təsviri", value: "description", sortable: false },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      options: {},
    };
  },
  watch: {
    options: {
      handler(value) {
        const { page, itemsPerPage, sortBy, sortDesc } = value;
        if (
          (sortBy.length > 0 && sortDesc.length > 0) ||
          page > 0 ||
          itemsPerPage >= 10
        ) {
          this.$emit("optionsChanged", {
            page: page,
            itemsPerPage: itemsPerPage,
            sortedBy: sortBy[0],
            sortedDesc: sortDesc[0],
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    editItem(item) {
      this.$emit("editItem", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
  },
};
</script>
